"use strict";
/**
 * Query Params:
 * @param cursor - the reference id
 * @param {"next" | "prev"} dir - get next or previous N rows
 * @param rows - row count
 * @param sort - [field]_["asc" | "desc"]
 *
 * Fields:
 * @param totalItems - always the absolute total of the dataset.
 * @param currentRange - the range being viewed currently based on `rows`.
 */
Object.defineProperty(exports, "__esModule", { value: true });
